<template>
  <div>
    <el-backtop :bottom="100" class="remove-bg">
      <svg t="1624287047541" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1280" width="32" height="32"><path d="M512 843.2c-36.2 0-66.4-13.6-85.8-21.8-10.8-4.6-22.6 3.6-21.8 15.2l7 102c0.4 6.2 7.6 9.4 12.6 5.6l29-22c3.6-2.8 9-1.8 11.4 2l41 64.2c3 4.8 10.2 4.8 13.2 0l41-64.2c2.4-3.8 7.8-4.8 11.4-2l29 22c5 3.8 12.2 0.6 12.6-5.6l7-102c0.8-11.6-11-20-21.8-15.2-19.6 8.2-49.6 21.8-85.8 21.8z" p-id="1281" fill="#ef4030"></path><path d="M795.4 586.2l-96-98.2c0-316-186.4-456-186.4-456s-188.2 140-188.2 456l-96 98.2c-3.6 3.6-5.2 9-4.4 14.2L261.2 824c1.8 11.4 14.2 17 23.6 10.8l134.2-90.8s41.4 40 94.2 40c52.8 0 92.2-40 92.2-40l134.2 90.8c9.2 6.2 21.6 0.6 23.6-10.8l37-223.8c0.4-5.2-1.2-10.4-4.8-14zM513 384c-34 0-61.4-28.6-61.4-64s27.6-64 61.4-64c34 0 61.4 28.6 61.4 64s-27.4 64-61.4 64z" p-id="1282" fill="#ef4030"></path></svg>
    </el-backtop>
  </div>
</template>

<script>
export default {
  name: 'BackTop'
}
</script>

<style lang="less" scoped>
  .remove-bg {
    background-color: transparent;
    box-shadow: none;
  }
</style>
