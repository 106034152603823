<template>
  <div class="container">
    <live2d
      :style="style"
      :direction="direction"
      :size="size"
      :home-page="'/'"
      :api-path="apiPath"
    ></live2d>
  </div>
</template>

<script>
import live2d from 'vue-live2d'

export default {
  name: 'App',
  components: {
    live2d
  },
  data () {
    return {
      direction: 'right',
      style: '',
      width: 500,
      height: 500,
      size: 350,
      apiPath: ''
    }
  },
  created () {
    if (process.env.NODE_ENV === 'production') {
      this.apiPath = 'https://lancekee.xyz/live2d-static/indexes'
    } else {
      this.apiPath = '/live2d-static/indexes'
    }
  }
}
</script>

<style lang="less" scoped>
  .container {
    position: fixed;
    left: 20px;
    bottom: 20px;
    z-index: 10;
  }
</style>
