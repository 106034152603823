import hljs from 'highlight.js/lib/common'

const highlightjs = (md, opts) => {
  opts = Object.assign({ hljs }, highlightjs.defaults, opts)

  return require('./core')(md, opts)
}

highlightjs.defaults = {
  auto: true,
  code: true,
  inline: false
}

export default highlightjs
