<template>
  <div class="container">
    <aside-profile/>
    <div class="main-box" v-loading="loading">
      <div v-if="keyword" class="keyword-tip">
        关键字 <span style="font-weight: bold">{{keyword}}</span> 的搜索结果如下：
      </div>
      <div class="article-item" v-if="articleList.length === 0">
        暂无数据，要不先<router-link to="" @click.native="refreshPage">回首页</router-link>
      </div>
      <div class="article-item" v-for="item in articleList" :key="item.id">
        <div class="title">
          {{ item.title }}
        </div>
        <div class="statistic-row-1">
          <div class="statistic-item" :title="item.createTime">
            <svg t="1622256843289" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 p-id="6334" width="20" height="20">
              <path
                d="M469.333333 597.333333v-128h85.333334v128h128v85.333334h-128v128h-85.333334v-128H341.333333v-85.333334h128z m384-256V213.333333h-85.333333v42.666667h-85.333333V213.333333H341.333333v42.666667H256V213.333333H170.666667v128h682.666666z m0 85.333334H170.666667v426.666666h682.666666V426.666667z m-85.333333-298.666667h85.333333a85.333333 85.333333 0 0 1 85.333334 85.333333v640a85.333333 85.333333 0 0 1-85.333334 85.333334H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333334V213.333333a85.333333 85.333333 0 0 1 85.333334-85.333333h85.333333V85.333333h85.333333v42.666667h341.333334V85.333333h85.333333v42.666667z"
                p-id="6335" fill="#4B97E8"></path>
            </svg>
            <span class="text-create">发表于 {{ item.createTime ? item.createTime.split(' ')[0] : '' }}</span>
          </div>
          <div class="statistic-item" :title="item.updateTime" v-if="item.updateTime">
            <svg t="1622256764297" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 p-id="5871" width="20" height="20">
              <path
                d="M853.333333 341.333333V213.333333h-85.333333v42.666667h-85.333333V213.333333H341.333333v42.666667H256V213.333333H170.666667v128h682.666666z m0 85.333334H170.666667v426.666666h682.666666V426.666667z m-85.333333-298.666667h85.333333a85.333333 85.333333 0 0 1 85.333334 85.333333v640a85.333333 85.333333 0 0 1-85.333334 85.333334H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333334V213.333333a85.333333 85.333333 0 0 1 85.333334-85.333333h85.333333V85.333333h85.333333v42.666667h341.333334V85.333333h85.333333v42.666667z m-298.666667 537.002667l183.168-183.168 60.330667 60.330666L469.333333 785.664l-158.165333-158.165333 60.330667-60.330667L469.333333 665.002667z"
                p-id="5872" fill="#419D58"></path>
            </svg>
            <span class="text-update">更新于 {{ item.updateTime.split(' ')[0]}}</span>
          </div>
          <div class="statistic-item">
            <svg t="1622256972988" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 p-id="5228" width="20" height="20">
              <path
                d="M512 192c-223.328 0-416.896 130.048-512 320 95.104 189.952 288.672 320 512 320s416.864-130.048 512-320c-95.104-189.952-288.672-320-512-320zM764.448 361.696c60.16 38.368 111.136 89.76 149.44 150.304-38.304 60.512-89.28 111.936-149.44 150.304-75.584 48.224-162.88 73.696-252.448 73.696s-176.864-25.472-252.448-73.696c-60.16-38.368-111.136-89.76-149.44-150.304 38.304-60.512 89.28-111.936 149.44-150.304 3.904-2.496 7.872-4.928 11.872-7.296-9.952 27.328-15.424 56.832-15.424 87.584 0 141.376 114.624 256 256 256s256-114.624 256-256c0-30.784-5.44-60.256-15.424-87.584 3.968 2.368 7.936 4.8 11.872 7.296l0 0zM512 416c0 53.024-42.976 96-96 96s-96-42.976-96-96 42.976-96 96-96 96 42.976 96 96z"
                p-id="5229" fill="#707070"></path>
            </svg>
            <span class="text-count">阅读次数：{{ item.visitCount }}</span>
          </div>
        </div>
        <div class="statistic-row-2">
          <div class="statistic-item">
            <svg t="1622257135721" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 p-id="7008" width="20" height="20">
              <path
                d="M128 469.333333v341.333334h768v-341.333334H128z m0-85.333333h768V298.666667h-384.213333c-30.677333-0.170667-50.602667-14.506667-72.106667-41.088-2.944-3.669333-12.373333-15.914667-13.781333-17.749334C409.898667 219.733333 400.384 213.333333 384 213.333333H128v170.666667z m768-170.666667a85.333333 85.333333 0 0 1 85.333333 85.333334v512a85.333333 85.333333 0 0 1-85.333333 85.333333H128a85.333333 85.333333 0 0 1-85.333333-85.333333V213.333333a85.333333 85.333333 0 0 1 85.333333-85.333333h256c47.786667 0 78.208 20.266667 108.757333 58.837333 2.048 2.56 11.136 14.378667 13.354667 17.152 6.741333 8.32 8.106667 9.344 5.973333 9.344H896z"
                p-id="7009" fill="#A8A558"></path>
            </svg>
            <span class="text-category">分类：{{ item.categoryList.join('、') }}</span>
          </div>
        </div>
        <div class="content">
          <Markdown :content="item.content"/>
        </div>
        <div class="more-btn">
          <el-button @click="gotoArticleDetail(item)"
                     round style="background-color: #73C8FF;color:#ffffff">
            阅读更多<i class="el-icon-d-arrow-right el-icon--right"></i>
          </el-button>
        </div>

      </div>
      <el-pagination
        hide-on-single-page
        @current-change="handleCurrentChange"
        :current-page.sync="pageNo"
        :page-size.sync="pageSize"
        :total.sync="total"
        layout="total, prev, pager, next"
        style="display: flex;justify-content: center">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import AsideProfile from '@/components/AsideProfile'
import Markdown from '@/components/Markdown'
import { listArticle } from '@/api/article'

export default {
  name: 'Home',
  components: {
    AsideProfile,
    Markdown
  },
  data () {
    return {
      pageNo: 1,
      pageSize: 5,
      total: 0,
      keyword: undefined,
      articleList: [],
      loading: false
    }
  },
  created () {
    this.keyword = this.$route.params.keyword
    this.getArticleList()
  },
  mounted () {
    this.$EventBus.$on('searchBlog', (keyword) => {
      this.keyword = keyword
      this.getArticleList()
    })
  },
  beforeDestroy () {
    this.$EventBus.$off('searchBlog', {})
  },
  methods: {
    refreshPage () {
      window.location.reload()
    },
    getArticleList () {
      this.loading = true
      const query = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        keyword: this.keyword
      }
      listArticle(query).then(res => {
        this.total = res.data.total
        this.articleList = res.data.records
      }).finally(() => {
        this.loading = false
      })
    },
    handleCurrentChange (val) {
      this.getArticleList()
    },
    gotoArticleDetail (article) {
      this.$router.push(`/article/${article.id}`)
      // 如果提供了 path，params 会被忽略
      // this.$router.push({
      //   name: 'ArticleDetail',
      //   params: {
      //     id: article.id
      //   }
      // })
    }
  }
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  justify-content: space-between;

  .main-box {
    width: 770px;

    .keyword-tip {
      margin: 10px 0;
    }

    .article-item {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 30px;

      .title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
      }

      .statistic-row-1, .statistic-row-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        .statistic-item {
          display: flex;
          align-items: center;
          margin: 0 10px;

          span {
            margin-left: 5px;
            font-size: 14px;
          }

          .text-create {
            color: #4B97E8;
          }

          .text-update {
            color: #419D58;
          }

          .text-count {
            color: #707070;
          }

          .text-category {
            color: #A8A558;
          }
        }
      }

      .content {
        margin: 20px 0;
      }

      .more-btn {

        text-align: center;
      }

    }
  }

}
</style>
