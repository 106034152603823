<template>
  <div class="container footer-bg">
    <div class="row-1">
      <div>本网站已经运行了{{ runtimeDisplay }}</div>
      <!--<div class="divider">|</div>-->
      <!--<div>访问人数：{{visitCount}}</div>-->
    </div>
    <div class="row-2">
      <div>版权声明 <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.zh" target="_blank">CC BY-NC-SA 4.0</a></div>
      <div class="divider">|</div>
      <div>&copy; 2021~present</div>
      <div class="divider">|</div>
      <div>
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021090228号</a>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

// import { siteStatistic } from '@/api/statistic'
export default {
  name: 'FooterBg',
  data () {
    return {
      // 网站运行时间定时器
      // timer: undefined,
      // 网站运行时间
      runtimeDisplay: undefined
      // 访问人数
      // visitCount: 2333
    }
  },
  methods: {
    changeTime () {
      const startTime = dayjs('2021-06-01', 'YYYY-MM-DD')
      const now = dayjs()
      const diff = dayjs.duration(now.diff(startTime))

      const diffData = diff.$d
      let diffStr = ''
      if (diffData.years > 0) {
        diffStr += diffData.years + '年'
      }
      if (diffData.months > 0) {
        diffStr += diffData.months + '月'
      }
      if (diffData.days > 0) {
        diffStr += diffData.days + '天'
      }
      diffStr += (diffData.hours < 10 ? '0' + diffData.hours : diffData.hours) + '小时'
      diffStr += (diffData.minutes < 10 ? '0' + diffData.minutes : diffData.minutes) + '分钟'
      diffStr += (diffData.seconds < 10 ? '0' + diffData.seconds : diffData.seconds) + '秒'

      this.runtimeDisplay = diffStr
    }
  },
  created () {
    // this.timer = setInterval(() => {
    //   this.changeTime()
    // }, 1000)
    // siteStatistic().then(res => {
    //   this.visitCount = res.data.visitCount
    // })
  },
  mounted () {
    const timer = setInterval(() => {
      this.changeTime()
    }, 1000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(timer)
    })
  }
  // beforeDestroy () {
  //   clearInterval(this.timer)
  // }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #3E639B;
  color: #E6E4E4;

  .row-1, .row-2 {
    display: flex;

    .divider {
      margin: 0 10px;
    }
  }

  .row-1 {
    margin: 0 auto;
  }

  .row-2 {
    margin: 10px auto;

    a {
      color: #E6E4E4;
    }
  }
}
</style>
