import request from '@/utils/request'

export function blogStatistic () {
  return request({
    url: 'statistic/blog'
  })
}

export function siteStatistic () {
  return request({
    url: 'statistic/site'
  })
}
