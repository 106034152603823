import { render, staticRenderFns } from "./Waifu.vue?vue&type=template&id=026a1bc4&scoped=true&"
import script from "./Waifu.vue?vue&type=script&lang=js&"
export * from "./Waifu.vue?vue&type=script&lang=js&"
import style0 from "./Waifu.vue?vue&type=style&index=0&id=026a1bc4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026a1bc4",
  null
  
)

export default component.exports