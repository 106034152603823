<template>
  <div class="aside-profile">
    <div class="swiper">
      <el-carousel indicator-position="none" arrow="never" height="134px">
        <el-carousel-item v-for="item in swiperList" :key="item">
          <img :src='require("../assets/op/" + item)' alt="op">
          <!--<img :src='item' alt="op">-->
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="motto">
      在激流勇进的时代活着
    </div>
    <div class="statistic">
      <div class="statistic-item">
        <router-link to="/">{{ statistic.blogCount }}</router-link>
        <div>
          博文
        </div>
      </div>
      <div class="statistic-item">
        <router-link to="/category">{{ statistic.categoryCount }}</router-link>
        <div>
          分类
        </div>
      </div>
      <div class="statistic-item">
        <router-link to="">{{ statistic.tagCount }}</router-link>
        <div>
          标签
        </div>
      </div>
    </div>
    <div class="link">
      <a class="github" href="https://github.com/zlx01" target="_blank">
        <svg t="1622274526924" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1416" width="40" height="40"><path d="M0 524.714667c0 223.36 143.146667 413.269333 342.656 482.986666 26.88 6.826667 22.784-12.373333 22.784-25.344v-88.618666c-155.136 18.176-161.322667-84.48-171.818667-101.589334-21.077333-35.968-70.741333-45.141333-55.936-62.250666 35.328-18.176 71.338667 4.608 112.981334 66.261333 30.165333 44.672 89.002667 37.12 118.912 29.653333a144.64 144.64 0 0 1 39.68-69.546666c-160.682667-28.757333-227.712-126.848-227.712-243.541334 0-56.576 18.688-108.586667 55.253333-150.570666-23.296-69.205333 2.176-128.384 5.546667-137.173334 66.474667-5.973333 135.424 47.573333 140.8 51.754667 37.76-10.197333 80.810667-15.573333 128.981333-15.573333 48.426667 0 91.733333 5.546667 129.706667 15.872 12.8-9.813333 76.885333-55.765333 138.666666-50.133334 3.285333 8.789333 28.16 66.602667 6.272 134.826667 37.077333 42.069333 55.936 94.549333 55.936 151.296 0 116.864-67.413333 215.04-228.565333 243.456a145.92 145.92 0 0 1 43.52 104.106667v128.64c0.896 10.282667 0 20.48 17.194667 20.48 202.410667-68.224 348.16-259.541333 348.16-484.906667C1023.018667 242.176 793.941333 13.312 511.573333 13.312 228.864 13.184 0 242.090667 0 524.714667z" p-id="1417" fill="#707070"></path></svg>
      </a>
      <!--<a class="csdn" href="https://blog.csdn.net/Lee_01" target="_blank">
        &lt;!&ndash;<svg t="1622260740242" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1823" width="50" height="50"><path d="M512 0c282.784 0 512 229.216 512 512s-229.216 512-512 512S0 794.784 0 512 229.216 0 512 0z m189.952 752l11.2-108.224c-31.904 9.536-100.928 16.128-147.712 16.128-134.464 0-205.728-47.296-195.328-146.304 11.584-110.688 113.152-145.696 232.64-145.696 54.784 0 122.432 8.8 151.296 18.336L768 272.704C724.544 262.24 678.272 256 599.584 256c-203.2 0-388.704 94.88-406.4 263.488C178.336 660.96 303.584 768 535.616 768c80.672 0 138.464-6.432 166.336-16z" fill="#CE000D" p-id="1824"></path></svg>&ndash;&gt;
        <div>csdn</div>
      </a>-->
    </div>
  </div>
</template>

<script>

import { blogStatistic } from '@/api/statistic'
import { mapState } from 'vuex'

export default {
  name: 'AsideProfile',
  components: {},
  data () {
    return {
      swiperList: [
        'Luffy.png', 'Zoro.png', 'Nami.png', 'Usopp.png', 'Sanji.png', 'Chopper.png', 'Robin.png', 'Franky.png', 'Brook.png'
      ]
      // cos链接
      // swiperList: [
      //   'https://lee-1300539932.cos.ap-guangzhou.myqcloud.com/md/20210617015754.png',
      //   'https://lee-1300539932.cos.ap-guangzhou.myqcloud.com/md/20210617015919.png',
      //   'https://lee-1300539932.cos.ap-guangzhou.myqcloud.com/md/20210617015934.png',
      //   'https://lee-1300539932.cos.ap-guangzhou.myqcloud.com/md/20210617015953.png',
      //   'https://lee-1300539932.cos.ap-guangzhou.myqcloud.com/md/20210617020021.png',
      //   'https://lee-1300539932.cos.ap-guangzhou.myqcloud.com/md/20210617020033.png',
      //   'https://lee-1300539932.cos.ap-guangzhou.myqcloud.com/md/20210617020045.png',
      //   'https://lee-1300539932.cos.ap-guangzhou.myqcloud.com/md/20210617020057.png',
      //   'https://lee-1300539932.cos.ap-guangzhou.myqcloud.com/md/20210617020110.png'
      // ]
      // 全球加速
      // swiperList: [
      //   'https://lee-1300539932.cos.accelerate.myqcloud.com/md/20210617015754.png',
      //   'https://lee-1300539932.cos.accelerate.myqcloud.com/md/20210617015919.png',
      //   'https://lee-1300539932.cos.accelerate.myqcloud.com/md/20210617015934.png',
      //   'https://lee-1300539932.cos.accelerate.myqcloud.com/md/20210617015953.png',
      //   'https://lee-1300539932.cos.accelerate.myqcloud.com/md/20210617020021.png',
      //   'https://lee-1300539932.cos.accelerate.myqcloud.com/md/20210617020033.png',
      //   'https://lee-1300539932.cos.accelerate.myqcloud.com/md/20210617020045.png',
      //   'https://lee-1300539932.cos.accelerate.myqcloud.com/md/20210617020057.png',
      //   'https://lee-1300539932.cos.accelerate.myqcloud.com/md/20210617020110.png'
      // ]
    }
  },
  computed: {
    ...mapState({
      statistic: state => state.statistic
    })
  },
  created () {
    if (this.statistic.init) {
      blogStatistic().then(res => {
        this.$store.commit('updateStatistic', res.data)
      })
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">

.aside-profile {
  box-sizing: border-box;
  width: 280px;
  height: 358px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;

  .swiper {
    height: 134px;
    width: 240px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .motto {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }

  .statistic {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;

    .statistic-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px;

      a {
        color: #333333;
        text-decoration: none;
        font-weight: bold;
      }

      div {
        margin-top: 5px;
      }
    }
  }

  .link {
    margin-top: 10px;
    height: 40px;
    display: flex;
    justify-content: space-around;

    .csdn {
      height: 40px;
      width: 40px;
      border-radius: 20px;
      overflow: hidden;
      color: #ce000d;

      border: 1px solid #717070;
      line-height: 40px;
      text-align: center;
      text-decoration: none;
    }
  }

}

</style>
