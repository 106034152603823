import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import {
  Button,
  Carousel,
  CarouselItem,
  Backtop,
  Input,
  Icon,
  Form,
  FormItem,
  Pagination,
  Popover,
  Loading,
  Message,
  MessageBox,
  Notification
} from 'element-ui'

import 'github-markdown-css/github-markdown.css'

import * as filters from './filters'

import SvgIcon from './components/SvgIcon.vue'

// Vue.use(ElementUI)
Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Backtop)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Pagination)
Vue.use(Popover)

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

Vue.component('svg-icon', SvgIcon)

// 遍历require.context的返回模块，并导入
const requireAll = requireContext => requireContext.keys().map(requireContext)

// import所有符合条件的svg 三个参数：文件夹、是否使用子文件夹、正则
const req = require.context('@/assets/icons', true, /\.svg$/)
requireAll(req)

// 全局注册过滤器
Object.keys(filters).forEach(k => Vue.filter(k, filters[k]))

Vue.config.productionTip = false

// 全局事件总线
Vue.prototype.$EventBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
