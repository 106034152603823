<template>
  <div class="app">
    <header-bg/>
    <div class="main">
      <router-view/>
    </div>
    <back-top/>
    <footer-bg/>
    <Waifu/>
  </div>
</template>

<script>
import HeaderBg from '@/components/HeaderBg'
import FooterBg from '@/components/FooterBg'
import BackTop from '@/components/BackTop'
import Waifu from '@/components/Waifu'

export default {
  components: {
    HeaderBg,
    FooterBg,
    BackTop,
    Waifu
  }
}
</script>

<style scoped lang="less">
.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  width: 1100px;
  margin: 70px auto 40px;
  flex: 1;
}

</style>
