import request from '@/utils/request'

export function listArticle (query) {
  return request({
    method: 'get',
    url: 'article',
    params: query
  })
}

export function getArticleById (articleId) {
  return request({
    method: 'get',
    url: `article/${articleId}`
  })
}

export function addComment (data) {
  return request({
    method: 'post',
    url: 'article/comment',
    data
  })
}

export function getComment (articleId) {
  return request({
    method: 'get',
    url: `article/comment/${articleId}`
  })
}
